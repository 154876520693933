<template>
    <div class="application_page">
        <new-header :config="headerConfig"></new-header>

        <div class="list_header">
            <div>订单信息</div>
            <div class="checked_div">
                <van-checkbox v-if="orderFlag" v-model="checkedAll" label-position="left"
                    @click="checkedAllChange" icon-size="16px">全选</van-checkbox>
            </div>
        </div>
        <div v-if="orderFlag" class="list_data">
            <van-checkbox-group v-model="checkedResult" ref="checkboxGroup" @change="checkedOneChange">
                <vue-loadmore :on-refresh="onRefresh" :on-loadmore="onLoad" :finished="finished">
                    <div v-for="(item, index) in list" class="list_card" :key="index">
                        <div class="card_header">
                            <div class="name_text">{{ item.goods_name }}</div>
                            <div><van-checkbox :name="item.order_id" icon-size="16px"></van-checkbox></div>
                        </div>
                        <van-divider />
                        <div class="card_data">
                            <div>订单编号：{{ item.order_sn }}</div>
                            <div>时间：{{ item.add_time }}</div>
                            <div>订单金额：{{ item.order_amount }}</div>
                        </div>
                        <div class="card_tag">
                            <van-icon class="tag_icon" :name="orderIcon" />{{ item.years }}年企业VIP权益
                        </div>
                    </div>
                </vue-loadmore>
            </van-checkbox-group>
        </div>
        <van-empty class="custom-image" v-else :image="errImage" description="系统检测，暂无有效订单，无法申请！" />

        <div v-if="orderFlag" class="list_tip">
            <div class="tip_time">可授权时间：{{ choseOrderTime }}</div>
            <div>*多选可叠加权限有效期</div>
        </div>

        <div class="list_btn">
            <van-button type="primary" round class="btn" color="#0091FF" @click="submit">
                {{ orderFlag ? '会员注册' : '我知道了' }}
            </van-button>
            <div class="agreement_div">
                <van-checkbox v-if="orderFlag" v-model="agreementChecked" shape="square" icon-size="16px">
                    我已只悉与了解
                    <span class="agreement" @click="goAgreement">《入会申请说明》</span>
                </van-checkbox>
            </div>
        </div>

        <div class="list_footer">
            <div class="text_item">申请说明：</div>
            <div class="text_item">1.通过参与平台活动即可免费获赠1年会员资格，切勿随意相信他人代办处理。 </div>
            <div class="text_item">2.暂时无其他方式成为企业会员，具体请关注集团通告！ </div>
            <div class="text_item">3.订单申请权益兑换后将不支持售后服务，如有异常人工处理。</div>
        </div>

        <div class="kefu_div" @click="goKefuPage">
            <van-icon class="kefu_icon" :name="kefuIcon" /><span>联系客服</span>
        </div>

        <new-dialog :config="dialogConfig" @confirm="dialogConfirmChange"></new-dialog>
    </div>
</template>
<script>
import errImage from '@/assets/personalIcon/listErr.png'
import kefuIcon from '@/assets/personalIcon/kefuIcon.png'
import orderIcon from '@/assets/personalIcon/orderIcon.png'

import newHeader from "@/components/newHeader";
import newDialog from '@/components/newDialog.vue';
import { getConversionOrder_api, getOrderTime_api, exchangeOrder_api } from '@/api/perdata'

// import { getCode_api } from "@/api/common";
// import Cookies from "js-cookie";
export default {
    components: {
        newHeader,
        newDialog
    },
    data() {
        return {
            errImage: '',
            kefuIcon: '',
            orderIcon: '',

            headerConfig: {
                show: true,
                title: '我的会员',
            },
            dialogConfig: {
                showDialog: false,
                text: '企业会员资格为平台活动赠送资格，请阅读申请说明内容，了解后进行操作。',
                cancelText: '考虑考虑',
                confirmText: '我已知晓',
            },

            checkedAll: false,
            checkedResult: [],
            choseOrderTime: 'XXXX-XX-XX xx:xx:xx',

            finished: false,
            pages: 0,
            query: { page: 1, limit: 10 },
            list: [],

            agreementChecked: false, //协议复选框

            orderFlag: false, //是否有订单
            routeCode: '', // app 跳转当前页面携带的用户code
        }
    },
    created() {
        this.errImage = errImage
        this.kefuIcon = kefuIcon
        this.orderIcon = orderIcon

        // app流程走当前页面
        if (this.$route.query && this.$route.query.code) {
            this.routeCode = this.$route.query.code
            // this.getUserInfo(this.routeCode)
        }

        this.getList()
    },
    methods: {
        // //获取用户信息
        // async getUserInfo(code) {
        //     let data = { code: code };
        //     getCode_api(data).then(res => {
        //         if (res.code == 0) {
        //             Cookies.set("token", res.data.access_token);
        //             this.getList()
        //         }
        //     });
        // },
        onRefresh(done) {
            this.list = [];
            this.query.page = 1;
            this.finished = false;
            this.getList();
            done();
        },
        onLoad(done) {
            if (this.query.page >= this.pages) {
                this.finished = true;
            } else {
                this.fetch();
            }
            done();
        },
        fetch() {
            this.query.page++;
            this.getList();
        },
        // 获取订单
        async getList() {
            let res = await getConversionOrder_api(this.query)
            if (res.code == 0) {
                res.data.forEach(item => {
                    item.goods_name = item.ordergoods.goods_name
                    item.order_amount = item.ordergoods.no_discount_price
                })
                this.list = this.list.concat(res.data)
                this.orderFlag = this.list.length > 0 ? true : false
                this.pages = res.pagination.pages
            }
        },
        // 获取选中订单时长
        async getOrderTime() {
            let res = await getOrderTime_api({ order_id: this.checkedResult })
            if (res.code == 0) {
                this.choseOrderTime = res.data.union_expire_at
                if (this.checkedResult.length == 0) this.choseOrderTime = 'XXXX-XX-XX xx:xx:xx'
            }
        },
        checkedAllChange() {
            this.getOrderTime()
            if (this.checkedAll == true) {
                this.$refs.checkboxGroup.toggleAll(true);
            } else {
                this.$refs.checkboxGroup.toggleAll();
            }
        },
        checkedOneChange(val) {
            this.getOrderTime()
            if (val.length == this.list.length) {
                this.checkedAll = true
            } else {
                this.checkedAll = false
            }
        },

        // 弹窗确认
        dialogConfirmChange() {
            this.dialogConfig.showDialog = false
            this.agreementChecked = true
        },

        // 提交申请
        async submit() {
            if (this.orderFlag) {
                if (this.checkedResult.length == 0) return this.$toast('请先选中申请订单')
                if (this.agreementChecked != true) return this.dialogConfig.showDialog = true

                let res = await exchangeOrder_api({ order_id: this.checkedResult })
                if (res.code == 0) {
                    setTimeout(() => {
                        if (this.routeCode) {
                            this.$router.push({ path: '/newLogin/applicationSuccess', query: { code: this.routeCode } })
                        } else {
                            this.$router.push('../newLogin/applicationSuccess')
                        }
                    }, 500);
                }
            }else{
                this.$router.push('../lador/ladorLogin')
            }
        },

        goAgreement() {
            this.$router.push("../newLogin/agreement"); // 入会申请说明
        },
        goKefuPage() {
            this.$router.push("../lador/service"); // 联系客服
        },
    }
}
</script>
<style lang="css" scoped>
.application_page {
    box-sizing: border-box;
    width: 100%;
    min-height: 100vh;
    padding: 20px 40px;
    background-color: #F9F9FB;
}

.list_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    font-size: 32px;
}

.checked_div {
    font-size: 26px;
}

.custom-image {
    min-height: 720px;
}

.custom-image>>>.van-empty__image {
    width: 196px;
    height: 196px;
}

.custom-image>>>.van-empty__description {
    width: 480px;
}

.list_data {
    margin: 20px 0;
    height: 600px;
    overflow: auto;
}

.list_card {
    width: 100%;
    height: 240px;
    border-radius: 20px;
    background: linear-gradient(180deg, #FEF8D3 0%, #FFFFFF 50%);
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.05);
    font-size: 24px;

    box-sizing: border-box;
    margin-bottom: 25px;
    position: relative;
}

.card_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    font-size: 28px;
    padding: 20px;
    box-sizing: border-box;
}

.name_text {
    width: 380px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.van-divider {
    margin: 0;
}

.card_data {
    padding: 15px 20px;
    box-sizing: border-box;
    height: auto;
    font-size: 24px;
    line-height: 42px;
    color: rgba(26, 26, 26, 0.8);
}

.card_tag {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 320px;
    height: 70px;
    background-color: #FEF8D3;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.05);
    border-radius: 70px 0 0 0;

    color: #FF9400;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    line-height: 70px;
}

.tag_icon {
    font-size: 55px;
    vertical-align: -15px;
    margin-right: 10px;
}

.list_tip {
    box-sizing: border-box;
    padding: 0 10px;
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
}

.tip_time {
    width: 100%;
    border-radius: 40px;
    height: 80px;
    background-color: #D8EEFF;
    color: #0091FF;
    font-size: 28px;
    line-height: 80px;
    margin-bottom: 15px;
}

.list_btn {
    text-align: center;
}

.btn {
    width: 320px;
    height: 88px;
}

.agreement_div {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
}

.agreement_div .agreement {
    color: #0091FF;
}

.list_footer {
    padding: 0 10px;
    font-size: 26px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 30px;
    line-height: 40px;
    text-align: left;
    margin-bottom: 20px;
}

.kefu_div {
    line-height: 33px;
    text-align: center;
    color: #0091FF;
    font-size: 28px;
}

.kefu_icon {
    vertical-align: -5px;
    margin-right: 10px;
}
</style>